.level-total {
  background-color: var(--gray-500) !important;
}

$colors: var(--yellow-500), var(--blue-500), var(--red-500), var(--green-500),
  var(--orange-500), var(--teal-500), var(--indigo-500), var(--bluegray-500);

@for $i from 1 to length($colors) {
  .level-#{$i} {
    background-color: nth($colors, $i) !important;
  }
}

.level-max {
  background-color: var(--gray-900) !important;
}

.level-projects {
  background-color: var(--gray-500) !important;
}

.neutral-badge {
  background-color: var(--gray-50) !important;
  color: #000 !important;
  margin-right: 0.25rem;
}

.grade {
  background-color: var(--surface-200);
  color: var(--surface-900);
  border-radius: 0.25rem;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  min-height: 1.5rem;
  padding: 0.125rem 0.375rem 0.125rem 0.375rem;
  display: flex;
  text-align: center;
}
